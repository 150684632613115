import React from "react";
import { Row, Container } from "reactstrap";
import { isMobile } from 'react-device-detect';

import ALL_CONTENT from "config/language";
import DeviceToggle from "components/Core/DeviceToggle";

function HomeFooter() {
  const FooterItem = (props) => {
    const href = isMobile && props.nav.hrefMobile ? props.nav.hrefMobile : props.nav.href;
    const key = props.nav.content || props.title; 
    return (
      <li key={key}><a href={href}>{key}</a></li>
    );
  };

  return (
    <DeviceToggle mobile={false}>
      <footer className="footer footer-black footer-white">
        <Container>
          <Row>
            <nav className="footer-nav">
              <ul>
                {/* {FooterItem(ALL_CONTENT.HOME)} */}
                {FooterItem(ALL_CONTENT.WORKSHOPS)}
                {FooterItem(ALL_CONTENT.PARTNERS)}
                {FooterItem(ALL_CONTENT.GALLERY)}
                {FooterItem(ALL_CONTENT.MEDIA)}
                {FooterItem(ALL_CONTENT.RESEARCH)}
                {FooterItem(ALL_CONTENT.PROFILE)}
                {FooterItem(ALL_CONTENT.CONTACT)}
              </ul>
            </nav>
          </Row>
        </Container>
      </footer>
    </DeviceToggle>
  );
}

export default HomeFooter;
