import React from "react";
import { createRoot } from 'react-dom/client';
import { BrowserRouter, Route, Routes } from "react-router-dom";

// icons
import { config, library } from '@fortawesome/fontawesome-svg-core';
import { faCalendarAlt, faIdBadge, faEnvelope, faEnvelopeOpen, faPaperPlane, faFilePdf } from '@fortawesome/free-regular-svg-icons';
import { faHome, faListUl, faUniversity, faVideo, faPhotoVideo, faTicketAlt, faAngleLeft, faAngleRight, faTimes, faMoon, faSun, faBookReader } from '@fortawesome/free-solid-svg-icons';
import { faFacebookSquare, faInstagram, faYoutube } from '@fortawesome/free-brands-svg-icons';

// styles
import "assets/css/bootstrap.min.css";
import "assets/scss/master.scss";
import "@fortawesome/fontawesome-svg-core/styles.css";

// pages
import HomePage from "views/HomePage";

// Import all the FontAwesome icons required for the website
config.autoAddCss = false;
library.add(faHome, faCalendarAlt, faIdBadge, faListUl, faUniversity, faVideo, faPhotoVideo, faEnvelope, faEnvelopeOpen, faFacebookSquare, faInstagram, faYoutube, faAngleLeft, faAngleRight, faPaperPlane, faTicketAlt, faTimes, faMoon, faSun, faFilePdf, faBookReader);

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
  <BrowserRouter>
    <Routes>
      <Route
        path="/"
        // render={props => <HomePage {...props} />}
        // element={props => <HomePage {...props} />}
        element={<HomePage />}
      />
    </Routes>
  </BrowserRouter>
);

// Initialise theme data props
if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
  document.body.dataset.systemTheme = "dark";
  document.body.dataset.selectedTheme = "dark";
} else {
  document.body.dataset.systemTheme = "light";
  document.body.dataset.selectedTheme = "light";
}
