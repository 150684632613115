import React, { Suspense } from "react";

// components
import HomeFooter from "components/Core/HomeFooter";
import HomeHeader from "components/Core/HomeHeader";
import HomeNavbar from "components/Core/HomeNavbar";

// sections
const ContactSection = React.lazy(() => import('components/Sections/ContactSection'));
const GallerySection = React.lazy(() => import('components/Sections/GallerySection'));
const MediaSection = React.lazy(() => import('components/Sections/MediaSection'));
const ProfileSection = React.lazy(() => import('components/Sections/ProfileSection'));
const ResearchSection = React.lazy(() => import('components/Sections/ResearchSection'));
const TestimonialsSection = React.lazy(() => import('components/Sections/TestimonialsSection'));
const WorkshopsSection = React.lazy(() => import('components/Sections/WorkshopsSection'));

function HomePage() {
  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    if(document.location.hash) {
      setTimeout(()=> {
          document
            .querySelector(document.location.hash)
            .scrollIntoView({ block: "start" })
      }, 1000);
    }

    document.body.classList.add("index");
    return function cleanup() {
      document.body.classList.remove("index");
    };
  });

  return (
    <Suspense fallback={<div style={{ 
      display: 'flex',
      overflow: 'hidden',
      height: '100vh',
      width: '100%',
      backgroundColor: '#3f5b98'
       }}>&nbsp;</div>}>
      <HomeNavbar />
      <HomeHeader />

      <div className="main">
        <WorkshopsSection />
        <TestimonialsSection />
        <GallerySection />
        <MediaSection />
        <ResearchSection />
        <ProfileSection />
        <ContactSection />
      </div>

      <HomeFooter />
    </Suspense>
  );
}

export default HomePage;
