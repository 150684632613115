import React from "react";

function HomeHeader() {
  return (
    <>
      <div
        id="home"
        className="page-header section-dark cnwm-header"
      >
      </div>
    </>
  );
}

export default HomeHeader;
