import React from "react";
import classnames from "classnames";
import { Collapse, Navbar, NavItem, NavLink, Nav, NavbarBrand } from "reactstrap";
import { isMobile } from 'react-device-detect';
import { useMediaQuery } from '@react-hook/media-query';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import ALL_CONTENT from "config/language";
import DeviceToggle from "components/Core/DeviceToggle";

function HomeNavbar() {
  const [navbarColor, setNavbarColor] = React.useState("navbar-transparent");
  const [navbarCollapse, setNavbarCollapse] = React.useState(false);
  const [navbarDarkMode, setDarkMode] = React.useState("");
  const showLabels = useMediaQuery("screen and (min-width: 1200px)");

  const getTheme = () => {
    if (document.body.dataset.selectedTheme)
      return document.body.dataset.selectedTheme;
    if (document.body.dataset.systemTheme)
      return document.body.dataset.systemTheme;

    if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
      return "dark";
    } else {
      return "light";
    }
  };

  if (!navbarDarkMode) { setDarkMode(getTheme()); }

  const toggleNavbarCollapse = (val) => {
    setNavbarCollapse(val !== false ? !navbarCollapse : val);
    document.documentElement.classList.toggle("nav-open");
  };

  const togglePreferredColorScheme = (event) => {
    event && event.preventDefault();

    // Read and switch the selected theme
    let system = document.body.dataset.systemTheme;
    let anti = system === "dark" ? "light" : "dark";
    let selected = document.body.dataset.selectedTheme;
    selected = selected === "dark" ? "light" : "dark";
    document.body.dataset.selectedTheme = selected;

    for (let i = document.styleSheets.length - 1; i >= 0; i -= 1) {
      try {
        for (let j = document.styleSheets[i].rules.length - 1; j >= 0; j -= 1) {
          let rule = document.styleSheets[i].rules[j].media;
          if (rule && rule.mediaText && rule.mediaText.includes("prefers-color-scheme")) {
            let oldText = rule.mediaText;
            let newText = "";
            if (oldText.includes(`prefers-color-scheme: ${system}`)) {
              newText = oldText.replace(`prefers-color-scheme: ${system}`, `prefers-color-scheme: ${anti}`);
              rule.appendMedium(newText);
              rule.deleteMedium(oldText);
            } else if (oldText.includes(`prefers-color-scheme: ${selected}`) || oldText.includes(`prefers-color-scheme: ${anti}`)) {
              newText = oldText.replace(`prefers-color-scheme: ${selected}`, `prefers-color-scheme: ${system}`).replace(`prefers-color-scheme: ${anti}`, `prefers-color-scheme: ${system}`);
              rule.appendMedium(newText);
              rule.deleteMedium(oldText);
            } 
          }
        }
      } catch (err) {
        // Ignore errors for inaccessible stylesheets
      }
    }

    setDarkMode(getTheme());
  };

  React.useEffect(() => {
    const updateNavbarColor = () => {
      if (
        document.documentElement.scrollTop > 299 ||
        document.body.scrollTop > 299
      ) {
        setNavbarColor("");
      } else if (
        document.documentElement.scrollTop < 300 ||
        document.body.scrollTop < 300
      ) {
        setNavbarColor("navbar-transparent");
      }
    };

    window.addEventListener("scroll", updateNavbarColor);

    return function cleanup() {
      window.removeEventListener("scroll", updateNavbarColor);
    };
  });

  const MenuItem = (props) => {
    const key = props.nav.content || props.title;
    return (
      <NavbarBrand key={key} data-placement="bottom" data-special={props.nav.special || undefined} {...props.nav}>
        {key}
      </NavbarBrand>
    );
  };

  const PanelItem = (props) => {
    const paragraphClass = showLabels && props.nav.showLabel ? "" : "d-lg-none";
    const key = props.nav.content || props.title;
    const navProps = {
      "data-placement": "bottom",
      "aria-label": key,
      rel: props.nav.rel,
      style: {
        paddingLeft: 12,
        paddingRight: showLabels && props.showLabel ? 24 : undefined
      },
      onClick: toggleNavbarCollapse,
      target: props.nav.target,
      href: isMobile && props.nav.hrefMobile ? props.nav.hrefMobile : props.nav.href
    };

    return (
      <NavItem key={key}>
        <NavLink data-special={props.nav.special || undefined} { ...navProps }>
          <FontAwesomeIcon icon={props.nav.badge} size="lg" fixedWidth={true} />
          <p className={paragraphClass}>&nbsp;&nbsp;{props.nav.content || props.title}</p>
        </NavLink>
      </NavItem>
    );
  };

  return (
    <Navbar className={classnames("fixed-top", navbarColor)} expand="lg">
      <div className="navbar-translate">
        <DeviceToggle mobile={false}>
          {/* {MenuItem(ALL_CONTENT.HOME)} */}
          {MenuItem(ALL_CONTENT.WORKSHOPS)}
          {MenuItem(ALL_CONTENT.PARTNERS)}
          {MenuItem(ALL_CONTENT.GALLERY)}
          {MenuItem(ALL_CONTENT.MEDIA)}
          {MenuItem(ALL_CONTENT.RESEARCH)}
          {MenuItem(ALL_CONTENT.PROFILE)}
          {MenuItem(ALL_CONTENT.CONTACT)}
        </DeviceToggle>

        <button
          type="button"
          aria-label="Dark Mode"
          className="darkmode-toggle"
          onClick={togglePreferredColorScheme}
        >
          <FontAwesomeIcon icon={ALL_CONTENT.THEME.nav.badge[navbarDarkMode]} size="lg" fixedWidth={true} />
        </button>

        <button
          type="button"
          aria-label="Menu"
          aria-expanded={navbarCollapse}
          className={classnames("navbar-toggler navbar-toggler-right", {
            toggled: navbarCollapse
          })}
          onClick={toggleNavbarCollapse}
        >
          <span className="navbar-toggler-bar bar1" />
          <span className="navbar-toggler-bar bar2" />
          <span className="navbar-toggler-bar bar3" />
        </button>
      </div>
      <Collapse
        className="justify-content-end"
        navbar
        isOpen={navbarCollapse}
      >
        <Nav navbar>
          <DeviceToggle mobile={true}>
            {PanelItem(ALL_CONTENT.HOME)}
            {PanelItem(ALL_CONTENT.WORKSHOPS)}
            {PanelItem(ALL_CONTENT.PARTNERS)}
            {PanelItem(ALL_CONTENT.GALLERY)}
            {PanelItem(ALL_CONTENT.MEDIA)}
            {PanelItem(ALL_CONTENT.RESEARCH)}
            {PanelItem(ALL_CONTENT.PROFILE)}
            {PanelItem(ALL_CONTENT.CONTACT)}
            <NavItem><hr/></NavItem>
          </DeviceToggle>

          {PanelItem(ALL_CONTENT.UPCOMING)}
          {PanelItem(ALL_CONTENT.FACEBOOK)}
          {PanelItem(ALL_CONTENT.INSTAGRAM)}
          {PanelItem(ALL_CONTENT.YOUTUBE)}
        </Nav>
      </Collapse>
    </Navbar>
  );
}

export default HomeNavbar;
