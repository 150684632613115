import React from "react";
import { useMediaQuery } from '@react-hook/media-query';

function DeviceToggle(props) {
  // max-width should match media setting used in cnwm.scss
  const result = useMediaQuery("screen and (max-width: 767px)");
  const show = props.mobile === result;

  return (
      show
        ? <>{props.children}</>
        : null
  );
}

export default DeviceToggle;
